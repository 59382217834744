// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-prawniczy-js": () => import("./../../../src/pages/blog-prawniczy.js" /* webpackChunkName: "component---src-pages-blog-prawniczy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-radca-prawny-gdansk-js": () => import("./../../../src/pages/radca-prawny-gdansk.js" /* webpackChunkName: "component---src-pages-radca-prawny-gdansk-js" */),
  "component---src-pages-zakres-uslug-js": () => import("./../../../src/pages/zakres-uslug.js" /* webpackChunkName: "component---src-pages-zakres-uslug-js" */),
  "component---src-pages-zespol-js": () => import("./../../../src/pages/zespol.js" /* webpackChunkName: "component---src-pages-zespol-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-team-member-template-js": () => import("./../../../src/templates/team-member-template.js" /* webpackChunkName: "component---src-templates-team-member-template-js" */)
}

