import React from "react"
import PageLinks from "../constants/links"
import {  motion } from "framer-motion"
// const svgLogo = 'M55 38.678l-2.939-2.94V23.306l2.935-1.093v-3.238l-2.935 1.093-14.613 5.485h-.02c-12.93-4.512-18.232-6.759-18.237-12.764h.005c0-4.826 4.846-8.752 10.804-8.752 4.21 0 7.825 1.98 9.607 4.832a3.298 3.298 0 00-2.383 3.16 3.304 3.304 0 006.52.76h.097c0-.161-.021-.318-.029-.478.008-.095.029-.185.029-.282 0-.353-.07-.686-.173-1.005C42.663 5.359 36.926 1 30.001 1c-7.63 0-13.836 5.283-13.84 11.78h-.006c0 7.57 6.254 10.667 16.86 14.432l-1.315.486-1.702.638-1.698-.638-20.363-7.63-2.935-1.094v3.238l2.935 1.093v12.442L5 38.684l2.937-.004v.008h3.036v-.01h2.951l-2.951-2.951V24.439l14.702 5.516-3.428 1.285c-.365-.122-.729-.263-1.113-.385l-2.956-1.012-.992 2.874.608.202 2.347.81c.75.263 1.458.506 2.146.75 13.7 4.775 18.516 7.03 18.517 12.857-.001 4.825-4.847 8.75-10.804 8.75-4.21 0-7.825-1.98-9.608-4.833a3.298 3.298 0 002.383-3.16 3.303 3.303 0 00-6.52-.759h-.095c0 .162.02.318.029.478-.009.094-.03.185-.03.281 0 .353.07.686.173 1.005 1.005 5.664 6.742 10.024 13.669 10.024 7.63 0 13.837-5.285 13.84-11.783v-.005c-.003-7.485-5.728-10.448-17.141-14.516l3.303-1.24 6.271 2.354 5.506 2.064 1.073-2.834-8.532-3.203 3.167-1.189.304-.121h.02l11.214-4.21v11.294l-2.956 2.935h.023l-.016.016 2.948-.004v.008h5.971l-.01-.01H55z'
import logo from "../assets/imgs/logo.svg";
import { layoutVariants, transition } from '../components/variants'

const Navbar = ({toggleSideBar, isOpen}) => {
  return (
    <nav className="navbar">
      <div className="nav-center">
        <div className="nav-header">
          <motion.a
            href="/"
            initial={{
              opacity: 0,
              y: '20px'
            }}
            animate={{
              y: 0,
              opacity: 1,
            }}
            transition={{delay: 1.3, duration: 1., ease: [0.6, 0.01, -0.05, 0.9]}}
            >
            {/*<svg*/}
            {/*  width={60}*/}
            {/*  height={89}*/}
            {/*>*/}
            {/*  <path d="M43.6563 28.5312L29.9997 42.1879L16.3431 28.5312H8.60254V31.4714C11.7167 31.4714 14.2499 33.997 14.2653 37.106V51.3997C14.2653 54.5215 11.7243 57.0625 8.60254 57.0625V60.0026H22.8656V58.5338V57.065C19.7438 57.065 17.2054 54.5241 17.2054 51.4023V37.1392C17.2054 37.129 17.2054 37.1213 17.2054 37.1111V33.5543L29.9997 46.3485L42.7939 33.5543V37.1341C42.7939 37.1367 42.7939 37.1367 42.7939 37.1392V51.4048C42.7939 54.5266 40.253 57.0676 37.1312 57.0676V60.0077C37.1338 60.0077 37.1338 60.0077 37.1338 60.0077H51.3968V58.5389V57.0701H51.3687C48.2571 57.0548 45.7315 54.519 45.7315 51.4074V37.129C45.7341 34.0072 48.2724 31.4714 51.3942 31.4714V28.5312H43.6563Z" fill="white"/>*/}
            {/*  <path d="M31.4694 65.6631H28.5319C28.5319 68.7849 25.9909 71.3258 22.8691 71.3258V74.266H37.1348V71.3258C34.0104 71.3284 31.4694 68.7875 31.4694 65.6631Z" fill="white"/>*/}
            {/*  <path d="M31.4714 51.397H28.5312V65.6626H31.4714V51.397Z" fill="white"/>*/}
            {/*  <path d="M30 0C13.457 0 0 13.457 0 30V58.5312C0 66.543 3.1218 74.0788 8.78966 79.7441C14.455 85.4094 21.9882 88.5312 30 88.5312C46.543 88.5312 60 75.0742 60 58.5312V30C60 13.457 46.543 0 30 0ZM57.0599 58.5312C57.0599 73.4519 44.9207 85.5911 30 85.5911C22.7738 85.5911 15.9775 82.7764 10.8675 77.6663C5.75486 72.5537 2.94012 65.7574 2.94012 58.5312V30C2.94012 15.0793 15.0793 2.94012 30 2.94012C44.9207 2.94012 57.0599 15.0793 57.0599 30V58.5312Z" fill="white"/>*/}
            {/*  <path d="M22.8656 17.2033H28.5283V37.1317H31.4684V17.2033H37.1312C40.253 17.2033 42.7939 19.7442 42.7939 22.866H45.734V14.2632H37.1312H22.8656H14.2627V22.866H17.2028C17.2028 19.7442 19.7438 17.2033 22.8656 17.2033Z" fill="white"/>*/}

            {/*</svg>*/}
            <img src={logo} alt="Radca prawny Gdańsk" />
          </motion.a>

          <motion.button
            initial={{
              opacity: 0,
              y: '20px'
            }}
            animate={{
              y: 0,
              opacity: 1,
            }}
            transition={{delay: 1.3, duration: 1., ease: [0.6, 0.01, -0.05, 0.9]}}
            type="button" className="toggle-btn" onClick={toggleSideBar} aria-label="menu-button">
            <div className={`nav-icon ${isOpen? "transformed" : ""}`}>
              <div/>
            </div>
          </motion.button>
        </div>
      </div>
      <PageLinks styleClass="nav-links"/>
    </nav>
  )
}

export default Navbar
